import Joi from "joi";
import React, { Fragment } from "react";

import InputField from "./InputField";

const schema = {
  mintcode: Joi.string().required(),
};

const FormGive = () => {
  return (
    <Fragment>
      <InputField label={"Mintcode:"} name={"mintcode"} validate={schema.mintcode} />
    </Fragment>
  );
};

export default FormGive;
