import { wildcardsContract } from "./";

export const TX_MINT = Symbol("TX_MINT");

export async function mint(tokenId, nonce, auth, user) {
  const { transactionHash: txHash } = await wildcardsContract.methods
    .mint(tokenId, nonce, auth)
    .send({
      from: user,
    });
  return { txHash, txMethod: TX_MINT };
}

