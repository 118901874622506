export const normalize = (val, max, min) => {
  return (val - min) / (max - min);
};

export const scale = (val, max, min) => {
  return ((max - min) * (val - 0)) / (1 - 0) + min;
};

export const getRandomInArray = (ar) => {
  return ar[Math.floor(Math.random()*ar.length)]
}
