import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import View from "../components/View";
import Mint from "../components/Mint";
import P5Wrapper from "../components/P5Wrapper";


import "../styles/Home.css";

const Home = () => {
  const { account, hasProvider } = useSelector((state) => state.ethereum);

  return (
    <Fragment>
      <View>
        { account ? <Mint /> : null}
        <P5Wrapper p5Props={{ hasProvider }}></P5Wrapper>
      </View>
    </Fragment>
  );
};

export default Home;
