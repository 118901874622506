import heart from '../assets/images/whiteheart-01.png';
import diamond from '../assets/images/whitediamond-01.png';
import spade from '../assets/images/whitespade-01.png';
import club from '../assets/images/whiteclub-01.png';

import { getRandomInArray } from "./procGen";

let canvasWidth;
let canvasHeight;

let x = 0;
let y = 0;
let xspeed = 3;
let yspeed = 1;

let w = 245 + 16 + 16;
let h = 45;
let button;
let loadedHeart;
let loadedDiamond;
let loadedClub;
let loadedSpade;

let enabled = false;
let animation = {};
let images = [];

let interval = 1;

export default function (p) { // eslint-disable-line
  p.setup = () => {
    // heart = p.loadImage('../assets/images/heart.png');
    //console.log(heart)
    //p.image(heart, 0, 0);

    canvasWidth = window.innerWidth;
    canvasHeight = window.innerHeight;

    p.createCanvas(canvasWidth, canvasHeight);
    p.noStroke();
    button = p.createButton('CONNECT');
    button.class('initial');
    p.background(0);
    p.loadImage(heart, img => {
      //p.image(img, 50, 50);
      loadedHeart = img;
    });
    p.loadImage(diamond, img => {
      //p.image(img, 50, 50);
      loadedDiamond = img;
    });
    p.loadImage(spade, img => {
      //p.image(img, 50, 50);
      loadedSpade = img;
    });
    p.loadImage(club, img => {
      //p.image(img, 50, 50);
      loadedClub = img;
    });
  };

  p.draw = () => {
    if (!enabled) {
      //p.background(5, 3, 38);
      button.position(x, y);
      x += xspeed;
      y += yspeed;
      if (x > canvasWidth - w || x < 0) {
        xspeed = -xspeed;
      }
      if (y > canvasHeight - h || y < 0) {
        yspeed = -yspeed;
      }
      button.mousePressed(() => {
        p.props.onEnable()
        enabled = true;
        p.removeElements()
        return false;
      });
    } else {
      if (p.frameCount % (interval * 30) === 0){
        images = [loadedClub, loadedDiamond, loadedHeart, loadedSpade];
        animation = {
          left: getRandomInArray(images),
          right: getRandomInArray(images),
          color: 1
        }
        if (canvasHeight * 2 < canvasWidth) {
          p.background(0);
          p.image(animation.left, 0, (canvasWidth-(canvasHeight*2))/2, canvasHeight, canvasHeight);
          p.image(animation.right, canvasHeight, 0, canvasHeight, canvasHeight);
        } else if (canvasWidth < canvasHeight) {
          p.background(0);
          p.image(animation.left, 0, 0, canvasWidth, canvasWidth);
          p.image(animation.right, 0, canvasWidth, canvasWidth, canvasWidth);
        } else {
          p.background(0);
          p.image(animation.left, 0, 0, canvasWidth/2, canvasWidth/2);
          p.image(animation.right, canvasWidth/2, 0, canvasWidth/2, canvasWidth/2);
        }
      }
    }
  };

}
