import PropTypes from "prop-types";
import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./views/Home";

const SessionContainer = ({ component: Component }) => {
  return <Component />;
};

const PublicRoute = ({ component, path }) => {
  return (
    <Route path={path}>
      <SessionContainer component={component} />
    </Route>
  );
};

// prettier-ignore
const Routes = () => (
  <Switch>
    <PublicRoute
      component={Home}
      exact
      path="/"
    />
  </Switch>
);

SessionContainer.propTypes = {
  component: PropTypes.elementType.isRequired,
  isTokenRequired: PropTypes.bool,
};

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  path: PropTypes.string.isRequired,
};

export default Routes;
