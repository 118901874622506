import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { useRequestForm } from "../hooks/form";
import { putRequest, getRequest } from "../store/api/actions";
import FormMint from "./FormMint";
import translate from "../services/translate";
import { useRequestId, useRequest } from "../hooks/request";
import { addPendingTransaction } from "../store/ethereum/actions";
import { usePendingTransaction } from "../hooks/ethereum";
import ButtonSubmit from "./ButtonSubmit";
import { mint, TX_MINT } from "../services/contracts/wildcards";
import web3 from "../services/web3";

import "../styles/Mint.css";

const Mint = (props) => {
  const { account, chainId } = useSelector((state) => state.ethereum);
  const dispatch = useDispatch();
  const mintRequestId = useRequestId();
  const tokenInfoRequestId = useRequestId();
  const [isMintcodeError, setIsMintcodeError] = useState();
  const [mintToken, setMintToken] = useState("");
  const [tokenInfo, setTokenInfo] = useState({});

  const { Form } = useRequestForm({
    onSubmit: async ({ mintcode }) => {
      const signature = await getSignature(mintcode);
      dispatch(
        putRequest({
          id: mintRequestId,
          endpoint: process.env.REACT_APP_API_URL,
          path: ["wildcards", "mintcodes"],
          body: {
            mintcode,
            signature,
          },
        })
      );
    },
  });

  const getSignature = (mintcode) => {
    return web3.eth.personal.sign(mintcode, account);
  }

  useRequest(mintRequestId, {
    onError: () => {
      setIsMintcodeError(true);
    },
    onSuccess: (response) => {
      if (response.status === "error") {
        setIsMintcodeError(true);
      } else {
        setMintToken(response.data);
        dispatch(getRequest({
          id: tokenInfoRequestId,
          endpoint: process.env.REACT_APP_API_URL,
          path: ["wildcards", response.data.tokenId],
        }))
      }
    },
  });

  useRequest(tokenInfoRequestId, {
    onError: (err) => {
      console.log(err)
    },
    onSuccess: (response) => {
      if (response.status === "error") {
        console.log(response)
      } else {
        setTokenInfo(response);
      }
    },
  });

  const mintTx = usePendingTransaction({
    txMethod: TX_MINT,
    params: { tokenId: mintToken.tokenId, account },
  });

  const onClickMint = async ({ auth, nonce }, user) => {
    const { txHash, txMethod } = await mint(
      mintToken.tokenId,
      nonce,
      auth,
      user
    );
    dispatch(
      addPendingTransaction({
        txHash,
        txMethod,
        params: {
          tokenId: mintToken.tokenId,
          account: user,
        },
      })
    );
  };

  return (
    <Fragment>
      <div className="mint">
        <div>
          {translate("Mint.connected", { account })}
        </div>
      {mintTx.isPending ? (
        <p>{translate("Mint.pending")}</p>
      ) : !mintToken ? (
          <Fragment>
            {!isMintcodeError ? (
              chainId !== process.env.REACT_APP_CHAINID ? (
                <p>{translate("Mint.wrongNetwork")}</p>
              ) :
                (<Form>
                  <FormMint />
                  <ButtonSubmit />
                </Form>
              )
            ) : (
              <Fragment>
                <p>{translate("Mint.emailError")}</p>
              </Fragment>
            )}
          </Fragment>
      ) : mintTx.isSuccess ? (
        <Fragment>
          <p>{translate("Mint.confirmation")}</p>
          {tokenInfo ? (
            <Fragment>
              <p>{translate("Mint.confirmation2", { name: tokenInfo.name })}</p>
              <img src={tokenInfo.image} alt={tokenInfo.name}/>
            </Fragment>
          ) : null}
        </Fragment>
      ) : mintTx.isError ? (
        <p>{translate("FormMint.error")}</p>
      ) : (
        <Fragment>
          <p>{translate("Mint.youCanBuyNow")}</p>
          <p>{translate("Mint.youCanBuyNow2")}</p>
          <button onClick={() => onClickMint(mintToken, account)}>Confirm</button>
        </Fragment>
      )}
      </div>
    </Fragment>
  )
};

Mint.propTypes = {
  close: PropTypes.func,
};

export default Mint;
