const components = {
  Mint: {
    connected: "Now connected as: {account}",
    emailError: `Could not validate mint code!`,
    wrongNetwork: "Please switch Metamask to mainnet Ethereum",
    youCanBuyNow: "Mint code validated!",
    youCanBuyNow2: "Proceed to mint?",
    confirmation: "Success!",
    confirmation2: "You were dealt the {name}"
  }
};

const views = {
  Home: {},
};

const copy = {
  title: "Wildcards",
  default: {
    buttonSubmit: "Submit",
  },
  ...components,
  ...views,
};

export default copy;
