import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import p5 from "p5";

import { enableAccount } from "../store/ethereum/actions";
import sketch from "./../services/sketch";

const P5Wrapper = ({ p5Props }) => {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  //const [canvas1, setCanvas1] = useState(null);

  useEffect(() => {
    const onEnable = (event) => {
      //event.preventDefault();
      dispatch(enableAccount());
    };

    if (!isLoaded) {
      const c = new p5(sketch, "canvasContainer")
      c.props = { ...p5Props, onEnable };
      setIsLoaded(true);
    } else {

    }

    return function cleanup() {
    }
  }, [isLoaded, p5Props, dispatch])

  return (
    <div id="canvasContainer" style={{}} />
  )
  
}

P5Wrapper.propTypes = {
  p5Props: PropTypes.object,
};

export default P5Wrapper;
