import React from "react";
import ReactDOM from "react-dom/client";
import PropTypes from "prop-types";
import { Provider } from "react-redux";

import './fonts/Appears.otf';
import './assets/images/whiteheart-01.png';
import "./styles/index.css";
import App from "./App";
import store from "./configureStore";

const Root = (props) => (
  <Provider store={props.store}>
    <App />
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

const container = document.getElementById('root');

const root = ReactDOM.createRoot(container);

root.render(<Root store={store} />);
